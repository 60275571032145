import momentUtil from '@/utils/MomentUtil';

export function getUnitPausedLabel(unit, timeZone) {
  const formattedPausedUntil = momentUtil(unit.active_showing_profile.paused_until, timeZone)
    .calendar(null, {
      sameDay: '[at] h:mmA [today]',
      nextDay: '[at] h:mmA [tomorrow]',
      nextWeek: '[on] dddd [at] h:mmA',
      sameElse: '[on] MMM Do [at] h:mmA',
    });

  const action = unit.status === 'auto_paused' ? 'stop' : 'resume';

  return `Will ${action} showing ${formattedPausedUntil}`;
}

export function getUnitFirstImage(unit) {
  return unit?.images?.length ? unit.images[0] : null;
}
